import { viewsetActions, request } from '@/service/plugin';

const osDatahubCache = {
  token: null,
  expiry: null,
};

const cases = viewsetActions('cases', {
  extraActions: {
    recordDecision: { path: 'record_decision', method: 'post', detail: true },
    cancel: { path: 'cancel', method: 'post', detail: true },
    archive: { path: 'archive', method: 'post', detail: true },
    logPdfGeneration: { path: 'log-pdf-generation', method: 'post', detail: true },
    sendReminder: { path: 'send-reminder', method: 'post', detail: true },
  },
});

const serviceActions = {
  // DBS Enhanced
  requestAdultFirst: { path: 'actions/request_adult_first', method: 'post', detail: true },
  // Driver Data
  changeNumber: { path: 'actions/change_number', method: 'post', detail: true },
  confirmNotFound: { path: 'actions/confirm_not_found', method: 'post', detail: true },
};

const caseActivities = viewsetActions('case-activities', {
  extraActions: {
    recordDecision: { path: 'record_decision', method: 'post', detail: true },
    start: { path: 'start', method: 'post', detail: true },
    notes: { path: 'notes', method: 'post', detail: true },
    notes_history: { path: 'notes_history', method: 'get', detail: true },
    ...serviceActions,
  },
});

const idvExtraActions = {
  verify: { path: 'verify', method: 'post', detail: true },
  unverify: { path: 'unverify', method: 'post', detail: true },
  countersign: { path: 'countersign', method: 'post', detail: true },
  collector: { path: 'collector', method: 'get', detail: true },
};
const idvCaseActivities = viewsetActions('idv-case-activities', { extraActions: idvExtraActions });

const caseAddresses = {
  ...viewsetActions('case-addresses', {
    extraActions: { osFakeLookup: { path: 'os-fake-lookup', method: 'get', detail: false } },
  }),
  osDatahubToken: async (options) => {
    const now = new Date();
    if (!osDatahubCache.expiry || now >= osDatahubCache.expiry) {
      const path = '/api/case-addresses/os-datahub-token/';
      const resp = await request('post', path, { ...options, skipSuccessMessage: true });
      if (resp.status === 200) {
        osDatahubCache.token = resp.data.access_token;
        osDatahubCache.expiry = new Date(now.getTime() + 1000 * resp.data.expires_in);
      }
    }
    return osDatahubCache.token;
  },
};

export default {
  cases,
  caseActivities,
  idvCaseActivities,
  caseBasicDetails: viewsetActions('case-basic-details'),
  caseBirthDetails: viewsetActions('case-birth-details'),
  casePassports: viewsetActions('case-passports'),
  caseDrivingLicences: viewsetActions('case-driving-licences'),
  caseOtherNames: viewsetActions('case-other-names'),
  caseAddresses,
  caseDocuments: viewsetActions('case-documents'),
  caseDBSDetails: viewsetActions('case-dbs-details'),
  caseDBSUpdateDetails: viewsetActions('case-dbs-update-details'),
  caseRtwDetails: viewsetActions('case-rtw-details', {
    extraActions: {
      documentTypes: { path: 'document-types', method: 'get', detail: false },
      setResult: { path: 'result', method: 'post', detail: true },
    },
  }),
};
