import cases from './cases';
import { opsUmbracoImports, opsUmbracoCollections, opsUmbracoRecords } from './umbraco';
import payments from './payments';
import { opsPriceBooks, opsPriceBookEntries } from './pricebooks';
import customers from './customers';

export default {
  ...cases,
  ...customers,
  ...payments,
  umbracoCollections: opsUmbracoCollections,
  umbracoImports: opsUmbracoImports,
  umbracoRecords: opsUmbracoRecords,
  pricebooks: opsPriceBooks,
  pricebookEntries: opsPriceBookEntries,
};
