const routes = [
  {
    path: 'login',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/Login.vue'),
    name: 'login',
  },
  {
    path: 'signup',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/Signup.vue'),
    name: 'signup',
  },
  {
    path: 'auth/new-customer-signup',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/NewCustomer.vue'),
    name: 'new-customer-signup',
  },
  {
    path: 'auth/user-not-ready',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/UserNotReady.vue'),
    name: 'user-not-ready',
  },
  {
    path: 'auth/password-reset/',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/PasswordReset.vue'),
    name: 'password-reset',
  },
  {
    path: 'auth/password-reset-confirm/',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/auth/PasswordResetConfirm.vue'
    ),
    props: true,
    name: 'password-reset-confirm',
  },
  {
    path: 'auth/set-password/',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/auth/PasswordResetConfirm.vue'
    ),
    props: true,
    name: 'new-user-password',
  },
  {
    path: 'auth/claim-account/',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/ClaimAccount.vue'),
    name: 'claim-account',
  },
  {
    // Callback for OIDC login
    path: 'auth/callback',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/Callback.vue'),
  },
  {
    // Callback for OIDC error
    path: 'auth/social-error',
    component: () => import(/* webpackChunkName: "public" */ '@/pages/auth/SocialAuthError.vue'),
  },
];
if (process.env.NODE_ENV === 'development') {
  routes.push(
    {
      path: 'demo-data-login',
      component: () => import(/* webpackChunkName: "devpublic" */ '@/pages/auth/DemoDataLogin.vue'),
      name: 'demo-data-login',
    },
  ); // add demo-data-login route here
}
export default routes;
