<template>
  <div>
    <v-list v-if="onlineIdvAssertion">
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssertion.current_name.first_name }}
              </v-list-item-title>
              <v-list-item-subtitle>Forename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="onlineIdvAssertion.current_name.middle_name">
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssertion.current_name.middle_name }}
              </v-list-item-title>
              <v-list-item-subtitle>Middlename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssertion.current_name.family_name }}
              </v-list-item-title>
              <v-list-item-subtitle>Surname</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ parseIsoDate(onlineIdvAssertion.date_of_birth) }}
              </v-list-item-title>
              <v-list-item-subtitle>Date of birth</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item v-if="onlineIdvCurrentAddress">
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvCurrentAddress }}
              </v-list-item-title>
              <v-list-item-subtitle>Current address</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssuranceLevel }}
              </v-list-item-title>
              <v-list-item-subtitle>Level of assurance</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="caseActivityDetails.config.scheme_type.includes('rtw')">
            <v-list-item-content>
              <v-list-item-title>
                {{ rtwVerified ? 'Verified' : 'Not Verified' }}
              </v-list-item-title>
              <v-list-item-subtitle>Right to Work</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="caseActivityDetails.config.scheme_type.includes('dbs')">
            <v-list-item-content>
              <v-list-item-title>
                {{ dbsVerified ? 'Verified' : 'Manual' }}
              </v-list-item-title>
              <v-list-item-subtitle>DBS Identity</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="onlineIdvNameMismatchReason">
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvNameMismatchReason }}
              </v-list-item-title>
              <v-list-item-subtitle>Name mismatch reason</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
    <p
      v-if="caseActivityDetails.result_data.manual_idv_approved"
      class="my-4"
    >
      Online DBS ID Verification could not be completed, manual ID verification has now begun.
    </p>
    <p
      v-if="caseActivityDetails.config.scheme_type.includes('rtw') && !rtwVerified"
      class="my-4"
    >
      Right to Work could not be digitally verified, please ensure you confirm the subject's
      Right to Work before employment.
    </p>
  </div>
</template>

<script>
import { parseYotiAddress, nameMismatchReasonItems } from '@/util/yoti';

export default {
  name: 'OnlineIdv',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    schemes() {
      const idProfile = this.caseActivityDetails.result_data.session?.identity_profile;
      return idProfile?.identity_profile_report?.schemes_compliance || [];
    },
    rtwVerified() {
      const rtwScheme = this.schemes.find((s) => s.scheme.type === 'RTW');
      return rtwScheme?.requirements_met;
    },
    dbsVerified() {
      const rtwScheme = this.schemes.find((s) => s.scheme.type === 'DBS');
      return rtwScheme?.requirements_met;
    },
    onlineIdvAssertion() {
      return this.caseActivityDetails.result_data?.id_profile?.identity_assertion;
    },
    onlineIdvCurrentAddress() {
      if (this.onlineIdvAssertion && this.onlineIdvAssertion.current_address?.address) {
        return this.getFullAddress(
          parseYotiAddress(this.onlineIdvAssertion.current_address.address),
        );
      }
      return null;
    },
    onlineIdvAssuranceLevel() {
      if (this.onlineIdvAssertion) {
        const verifReport = this.caseActivityDetails.result_data.id_profile.verification_report;
        return verifReport.assurance_process.level_of_assurance;
      }
      return null;
    },
    onlineIdvNameMismatchReason() {
      const reason = this.caseActivityDetails.result_data?.name_mismatch_reason;
      if (reason === 'other') {
        return this.caseActivityDetails.result_data.name_mismatch_other_reason;
      }
      if (reason) {
        return nameMismatchReasonItems.find((item) => item.value === reason).label;
      }
      return null;
    },
  },
  methods: {
    getFullAddress(item) {
      return [
        item.line_one_dbs || item.line_one,
        item.line_two_dbs || item.line_two,
        item.town_dbs || item.town,
        item.postcode,
      ].filter(Boolean).join(', ');
    },
    parseIsoDate(val) {
      const date = new Date(
        Date.UTC(val.substring(0, 4), val.substring(5, 7) - 1, val.substring(8, 10)),
      );
      return date.toUTCString().substring(4, 17);
    },
  },
};
</script>
